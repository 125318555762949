.SynergyGods {
    height: 100vh;
    width: 100vw;
    background: #000;
}

svg {
    height: 70vh;
    width: 100vw;
}

.pulse:hover,
.pulse:focus {
    -webkit-animation: pulse 1s;
    animation: pulse 1s;
    box-shadow: 0 0 0 2em transparent;
}

@-webkit-keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 var(--hover);
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 var(--hover);
    }
}

.pulse {
    --color: #00ff00;
    --hover: #0fff00;
}

button:hover, button:focus {
    border-color: var(--hover);
    color: #00ff00;
}

button {
    cursor: pointer;
    color: var(--color);
    transition: 0.25s;
    background: none;
    border: 2px solid;
    font: inherit;
    line-height: 1;
    margin: 0.5em;
    padding: 1em 2em;
}

.topper {
    height: 20vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.entry {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.entry > div > input {
    color: #00ff00;
    background: #000;
    margin: 8px;
    font-size: 24px;
}


/* Tablet styles */
@media screen and (min-width: 768px) {
    .entry {
        width: 65%;
    }
}

/* Desktop styles */
@media screen and (min-width: 1024px) {
    .entry {
        width: 75%;
    }
}
