.SurfingTheCity {
    text-align: center;
    background: black;
    height: 100vh;
    width: 100vw;
}

.instructions {
    color: white;
}
